import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import DigitalEnablerContainer from '../containers/DigitalEnablerContainer'

const DigitalExplorerPage = (props) => (
  <Layout 
  pageInfo={{ pageName: "Our Services" }} 
  styleClass="home-page " 
  location={props.location.pathname}>
    <SEO title="Our Services" />
    <DigitalEnablerContainer />
  </Layout>
)

export default DigitalExplorerPage
