import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import WorkStrategy from '../components/services/WorkStrategy'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import { digitalProductEngineering } from '../staticdata/homepageServicesData'
import { Col, Container, Row } from 'react-bootstrap'
import { digitalEngineering } from '../staticdata/threeTData'
import ServicesNewComponent from '../components/services/ServicesNewComponent'
class DigitalEnablerContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Digital Product Engineering "
                    bannerImage={banner}
                    text="Gain a competitive advantage with GTEN Technologies. Design, develop, modernize and manage your portfolio with us" />
                <Container>
                    <Row className="py-5 px-3">
                        <Col>
                            <p className="initial-paragrapgh">
                                World’s leading software organizations are transforming their business by the adoption of cloud, microservices architecture, API-led connectivity and DevOps using new disruptive software-driven business models. With this increased importance and complexity of managing product portfolios and engineering, what you need is a partner you can rely on.With a decade of leadership in software product engineering, we bring together the learnings, expertise, business models and technology to design, architect, develop and deploy your digital application portfolio. Our team is known for delivering efficiencies, speed to market, innovation and differentiation in competitive markets.
                        </p>
                        </Col>
                    </Row>
                </Container>
                <ServicesNewComponent
                    height="300px"
                    heading="Offerings & Solutions"
                    data={digitalEngineering} />
                <Container>
                    <Row>
                        <Col>
                            <h2 className="font-weight-bold pt-5 text-standard">Why GTEN Technologies</h2>
                        </Col>
                    </Row>
                </Container>
                <WorkStrategy data={digitalProductEngineering} />
                <hr />
                <CaseStudyComponent />
                <TestimonialComponent />
            </>
        )
    }
}

export default DigitalEnablerContainer